@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';






































































.site-card {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media screen and (max-width: $breakpoint-sm-max) {
    align-items: center;
  }

  &.auto {
    .section {
      max-width: 600px !important;
    }
  }

  .section {
    display: block;
    text-decoration: none;
    // height: 400px;
    width: 100%;
    max-width: 500px;
    padding: 0 0;
    /* position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, 0); */

    header {
      width: 100%;
      // position: absolute;
      // top: 0;
      // left: 0;

      h1 {
        text-align: center;
        font-size: 32px;
        line-height: 40px;
        margin: 0;
        color: white;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }

      h2 {
        font-size: 26px;
        line-height: 26px;
        margin: 0;
        text-align: center;
        font-weight: 400;
        color: white;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform: translateY(20px);
      }
    }

    &:hover {
      h2 {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
  .footer {
    background-color: #fff;
    height: 200px;
    padding: 40px;
    display: none;
  }

  @media screen and (max-width: $breakpoint-sm-max) {
    .section {
      z-index: 0;
      header {
        h1 {
          text-align: center;
          font-size: 32px;
          line-height: 32px;
        }
        h2 {
          opacity: 1;
          font-size: 20px;
          line-height: 20px;
          transform: translateY(0px);
        }
      }
    }
  }
}
