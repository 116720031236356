@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

























































































.page-index {
  header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    .logo {
      padding: 10px 20px;
      display: inline-block;

      img {
        width: 140px;

        @media screen and (max-width: $breakpoint-sm-max) {
          width: 80px;
        }
      }
    }
  }

  main {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: hidden;
    display: flex;
    justify-content: stretch;
    align-items: flex-start;

    .column {
      flex: 1 1 0px;
      transition: all 0.4s linear;
      min-width: 0%;
      height: 100%;

      &:hover {
        flex-grow: 2;
      }
    }
  }

  .sites-carousel {
    height: calc(100vh - 60px);
  }
}
