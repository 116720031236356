@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';


















































.footer-index {
  background-color: #030b0f;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  @media screen and (max-width: $breakpoint-sm-max) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  p {
    margin: 0;
  }

  .copyright {
    text-decoration: none;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    line-height: 14px;
  }
}
