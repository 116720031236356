.site-card {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end; }
  @media screen and (max-width: 1023px) {
    .site-card {
      align-items: center; } }
  .site-card.auto .section {
    max-width: 600px !important; }
  .site-card .section {
    display: block;
    text-decoration: none;
    width: 100%;
    max-width: 500px;
    padding: 0 0;
    /* position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, 0); */ }
    .site-card .section header {
      width: 100%; }
      .site-card .section header h1 {
        text-align: center;
        font-size: 32px;
        line-height: 40px;
        margin: 0;
        color: white;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
      .site-card .section header h2 {
        font-size: 26px;
        line-height: 26px;
        margin: 0;
        text-align: center;
        font-weight: 400;
        color: white;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        opacity: 0;
        transition: all 0.3s ease-in-out;
        transform: translateY(20px); }
    .site-card .section:hover h2 {
      opacity: 1;
      transform: translateY(0px); }
  .site-card .footer {
    background-color: #fff;
    height: 200px;
    padding: 40px;
    display: none; }
  @media screen and (max-width: 1023px) {
    .site-card .section {
      z-index: 0; }
      .site-card .section header h1 {
        text-align: center;
        font-size: 32px;
        line-height: 32px; }
      .site-card .section header h2 {
        opacity: 1;
        font-size: 20px;
        line-height: 20px;
        transform: translateY(0px); } }
